import { render, staticRenderFns } from "./CollaborateDoctor.vue?vue&type=template&id=1aedacdc"
import script from "./CollaborateDoctor.vue?vue&type=script&lang=js"
export * from "./CollaborateDoctor.vue?vue&type=script&lang=js"
import style0 from "./CollaborateDoctor.vue?vue&type=style&index=0&id=1aedacdc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports