<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="flex succesuss">
    <div slot="no-body">
      <div class="justify-center items-center">
        <div class="d-theme-dark-bg" style="border-radius: 15px">
          <p class="MsoNormal"><b><span  style="font-size:16.0pt;line-height:115%;color:#365F91;mso-themecolor:accent1;
mso-themeshade:191">{{$t('Doctors')}} </span></b></p>

<p class="MsoNormal"><b><span  style="font-size:16.0pt;line-height:115%;color:#365F91;mso-themecolor:accent1;
mso-themeshade:191">&nbsp;</span></b></p>

<p class="MsoNormal"><b><span  style="font-size:14.0pt;line-height:115%;color:#00B050">{{$t('Ehealth')}}</span></b></p>

<p class="MsoNormal" style="margin-left:2.0in;text-indent:.5in"><b><span  style="font-size:14.0pt;
line-height:115%;color:#365F91;mso-themecolor:accent1;mso-themeshade:191">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-left:2.0in;text-indent:.5in"><b><span  style="font-size:14.0pt;
line-height:115%;color:#365F91;mso-themecolor:accent1;mso-themeshade:191">{{$t('Headcompetition')}}</span></b></p>

<p class="MsoNormal"><b><span  style="font-size:14.0pt;line-height:115%;color:#00B050">&nbsp;</span></b></p>

<p class="MsoNormal"><b><span  style="font-size:14.0pt;line-height:115%;color:#00B050">{{$t('Bringexpertise')}}&nbsp;&nbsp;&nbsp;  </span></b></p>

<p class="MsoNormal"><b><span  style="font-size:14.0pt;line-height:115%;color:#00B050">&nbsp;</span></b></p>

<p class="MsoNormal"><b><span  style="font-size:14.0pt;line-height:115%;color:#00B050">&nbsp;</span></b></p>

<p class="MsoNormal"><b><span  style="font-size:12.0pt;line-height:115%">&nbsp;</span></b></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('Joining')}}<span style="background:#980000"> </span></span></p>

<p class="vx-row"> <img src="../assets/images/GreenPoint.png" alt=""> <span class="mt-6">{{$t('phase1')}}</span></p>

<p class="vx-row"> <img src="../assets/images/GreenPoint.png" alt=""> <span class="mt-6">{{$t('phase3')}} </span></p>

<p class="vx-row"> <img src="../assets/images/GreenPoint.png" alt=""> <span class="mt-6">{{$t('phase2')}}</span></p>

<p class="vx-row"> <img src="../assets/images/GreenPoint.png" alt=""> <span class="mt-6">{{$t('phase4')}} </span></p>

<p class="vx-row"> <img src="../assets/images/GreenPoint.png" alt=""> <span class="mt-6">{{$t('phase5')}} </span></p>

<p class="MsoNormal"><b><span  style="font-size:12.0pt;line-height:115%;color:#244061;mso-themecolor:accent1;
mso-themeshade:128">&nbsp;</span></b></p>

 <video-player  class="video-player-box"
                 ref="videoPlayer"
                 :options="playerOptions"
                 :playsinline="true"
                 customEventName="customstatechangedeventname"
 >
  </video-player>
<p class="MsoNormal"><b><span  style="font-size:12.0pt;line-height:115%">&nbsp;</span></b></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#1F497D;mso-themecolor:text2">{{$t('RegNow')}}</span></p>

<p class="MsoNormal"><span ><a href="https://www.doclinia.com/pages/register/2"><b><span style="font-size:12.0pt;line-height:115%;color:#00B050">https://www.doclinia.com/pages/register/2</span></b></a></span><b><span  style="font-size:12.0pt;
line-height:115%;color:#00B050"> </span></b></p>

<p class="MsoNormal"><b><span  style="font-size:12.0pt;line-height:115%;color:#00B050">&nbsp;</span></b></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </span></p>

<p class="MsoNormal"><b><span  style="font-size:15.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">{{$t('HaveQuestion')}} </span></b></p>

<p class="MsoNormal"><span  style="font-size:12.0pt;line-height:115%;
color:#00B050">{{$t('ContactUs')}}&nbsp;</span><span ><a href="mailto:info@doclinia.com"><span style="font-size:12.0pt;line-height:115%;
color:#00B050">info@doclinia.com</span></a></span><span  style="font-size:12.0pt;line-height:115%;color:#00B050"> </span></p>

<span  style="font-size:11.0pt;line-height:115%;font-family:&quot;Arial&quot;,&quot;sans-serif&quot;;
mso-fareast-font-family:Arial;color:#1F497D;mso-themecolor:text2;mso-ansi-language:
EN;mso-fareast-language:EN-US;mso-bidi-language:AR-SA"><br clear="all" style="mso-special-character:line-break;page-break-before:always">
</span>

<p class="MsoNormal"><b><span  style="font-size:12.0pt;line-height:115%;color:#1F497D;mso-themecolor:text2">&nbsp;</span></b></p>

<p class="MsoNormal"><b><span  style="font-size:12.0pt;line-height:115%">&nbsp;</span></b></p>
</div>
    </div>
  </div>
  </div>
</template>

<script>
import myVideo from 'vue-video'
import { videoPlayer } from 'vue-video-player'

export default {
  data() {
    return {
       playerOptions: {
          // videojs options
          muted: false,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: "video/mp4",
            src: require("../assets/DocliniaDemoVideo.mp4")
          }],
          poster: "/static/images/author.jpg",
        },
       video: {
                sources: [{
                    src: '@/assets/DocliniaDemoVideo.mp4',
                    type: 'video/mp4'
                }],
                options: {
                    autoplay: true,
                    volume: 0.6,
                    poster: 'http://covteam.u.qiniudn.com/poster.png'
                }
            }
    };
  },
  props: {
    Message: {
      type: String,
      default: () => "",
    },
  },
  components: {
    myVideo,
    videoPlayer
  },
  computed: {},
  created() {},
};
</script>

<style lang="scss">
.succesuss {
  width: 100%;
  margin: auto;
}
.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  text-align: center;
}
.P-Text {
  color: #004477;
}
</style>


